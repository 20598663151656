<template>
  <span>
    <v-dialog v-model="dialogDetalhes" fullscreen :scrollable="true">
      <v-card v-if="dialogDetalhes">
        <v-card-title class="py-2 text-center">
          <div class="headline">Detalhes Validações</div>
          <div class="body-1">
            {{ base }} -
            {{
              `${competencia_de}-01` | parseToCustomDateFormat("MM/YYYY")
            }}
            até
            {{ `${competencia_ate}-01` | parseToCustomDateFormat("MM/YYYY") }}
          </div>
        </v-card-title>
        <v-card-text class="py-0 px-2">
          <v-row dense justify="space-around">
            <v-col sm="12" md="3" class="py-0 mb-1">
              <v-checkbox
                label="Mostrar apenas linhas com Erro Crítico"
                v-model="mostrarApenasLinhasComErroCritico"
                hide-details
              />
            </v-col>
            <v-col sm="12" md="3" class="py-0 mb-1">
              <v-checkbox
                label="Mostrar apenas linhas com Datas iguais a Competência"
                v-model="mostrarApenasDatasIgualCompetencia"
                hide-details
              />
            </v-col>
            <v-col cols="auto" v-if="userInfo.admin">
              <v-btn
                color="primary"
                dark
                elevation="1"
                relative
                text
                small
                :loading="downloadingArquivoLogsAprovacoes"
                @click="downloadArquivoLogsAprovacoes()"
              >
                <v-icon left size="24px">
                  mdi-text-box-outline
                </v-icon>
                Exportar Logs
              </v-btn>
            </v-col>
          </v-row>
          <v-data-table
            :headers="availableHeaders"
            :items="filteredItems"
            dense
            class="elevation-1 style-table-dialog-detalhes"
            :footer-props="{
              itemsPerPageOptions: [10, 20, 50, 100, 200, -1],
              showCurrentPage: true,
              showFirstLastPage: true,
            }"
            :mobile-breakpoint="10"
            :page.sync="page"
          >
            <template v-slot:[`header.aprovacao_qtd_consumidor`]="{ header }">
              <v-row justify="end" no-gutters>
                <v-checkbox
                  v-model="flagTodosAprovadoQtdConsumidor"
                  :ripple="false"
                  hide-details
                  class="my-0"
                  :disabled="
                    savingAprovacoesValidacoes ||
                      !userIsAuthorizedAprovacaoQtdConsumidor() ||
                      (!authorizedCompanies.includes(
                        $store.getters.getCompanyId
                      ) &&
                        !isAdmin)
                  "
                  @change="triggerTodosAprovadoQtdConsumidor"
                >
                  <template #prepend>
                    <label class="v-label">
                      {{ header.text }}
                    </label>
                  </template>
                </v-checkbox>
              </v-row>
            </template>
            <template v-slot:[`header.aprovacao_qtd_reclamacao`]="{ header }">
              <v-row justify="end" no-gutters>
                <v-checkbox
                  v-model="flagTodosAprovadoQtdReclamacao"
                  :ripple="false"
                  hide-details
                  class="my-0"
                  :disabled="
                    savingAprovacoesValidacoes ||
                      !userIsAuthorizedAprovacaoQtdReclamacao() ||
                      (!authorizedCompanies.includes(
                        $store.getters.getCompanyId
                      ) &&
                        !isAdmin)
                  "
                  @change="triggerTodosAprovadoQtdReclamacao"
                >
                  <template #prepend>
                    <label class="v-label">
                      {{ header.text }}
                    </label>
                  </template>
                </v-checkbox>
              </v-row>
            </template>
            <template v-slot:[`header.aprovacao_datas`]="{ header }">
              <v-row justify="end" no-gutters>
                <v-checkbox
                  v-model="flagTodosAprovadoDatas"
                  :ripple="false"
                  hide-details
                  class="my-0"
                  :disabled="
                    savingAprovacoesValidacoes ||
                      !userIsAuthorizedAprovacaoDatas() ||
                      (!authorizedCompanies.includes(
                        $store.getters.getCompanyId
                      ) &&
                        !isAdmin)
                  "
                  @change="triggerTodosAprovadoDatas"
                >
                  <template #prepend>
                    <label class="v-label">
                      {{ header.text }}
                    </label>
                  </template>
                </v-checkbox>
              </v-row>
            </template>
            <template
              v-for="validacao in validacoes[base]"
              v-slot:[getItemSlotName(validacao)]="{ item }"
            >
              <div
                :key="validacao"
                class="text-truncate"
                style="max-width: 12vw; white-space: no-wrap"
              >
                <span
                  class="font-weight-normal"
                  v-if="
                    ['qtd_reclamacao', 'qtd_consumidor'].includes(validacao)
                  "
                >
                  {{ item[validacao] | parseNumberToFloatBrIgnoringNaN }}
                </span>
                <span class="font-weight-normal" v-else>
                  {{
                    item[validacao] == undefined || item[validacao].length != 7
                      ? "NA"
                      : item[validacao]
                  }}
                </span>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on">
                      <v-icon
                        style="padding-bottom: 1.5px;"
                        :color="
                          {
                            VALIDO: 'success',
                            ALERTA: 'warning',
                            ERRO_CRITICO: 'error',
                          }[item[`tipo_validacao_${validacao}`]]
                        "
                      >
                        {{
                          {
                            VALIDO: "mdi-check-circle-outline",
                            ALERTA: "mdi-alert-circle-outline",
                            ERRO_CRITICO: "mdi-close-circle",
                          }[item[`tipo_validacao_${validacao}`]]
                        }}
                      </v-icon>
                    </span>
                  </template>
                  <span
                    :class="
                      {
                        VALIDO: 'success--text font-weight-bold',
                        ALERTA: 'warning--text font-weight-bold',
                        ERRO_CRITICO: 'error--text font-weight-bold',
                      }[item[`tipo_validacao_${validacao}`]]
                    "
                  >
                    {{
                      {
                        VALIDO: "",
                        ALERTA: "ALERTA:",
                        ERRO_CRITICO: "ERRO CRÍTICO:",
                      }[item[`tipo_validacao_${validacao}`]]
                    }}
                  </span>
                  <span>
                    {{ item[`validacao_${validacao}`] }}
                  </span>
                </v-tooltip>
              </div>
            </template>
            <template v-slot:[`item.aprovacao_qtd_consumidor`]="{ item }">
              <v-row no-gutters justify="end">
                <v-col cols="auto">
                  <v-checkbox
                    v-model="item.aprovacao_qtd_consumidor"
                    hide-details
                    :disabled="
                      savingAprovacoesValidacoes ||
                        !userIsAuthorizedAprovacaoQtdConsumidor() ||
                        (!authorizedCompanies.includes(
                          $store.getters.getCompanyId
                        ) &&
                          !isAdmin)
                    "
                    :ripple="false"
                    :indeterminate="item.aprovacao_qtd_consumidor === null"
                    :success="item.aprovacao_qtd_consumidor === true"
                    :error="item.aprovacao_qtd_consumidor === false"
                    class="my-0"
                  >
                    <template v-slot:label>
                      <div
                        class="font-weight-medium"
                        :class="
                          getCheckboxAprovacaoClass(
                            item.aprovacao_qtd_consumidor
                          )
                        "
                        style="font-size: 12.5px"
                      >
                        {{
                          getCheckboxAprovacaoText(
                            item.aprovacao_qtd_consumidor
                          )
                        }}
                      </div>
                    </template>
                  </v-checkbox>
                </v-col>
              </v-row>
            </template>
            <template v-slot:[`item.aprovacao_qtd_reclamacao`]="{ item }">
              <v-row no-gutters justify="end">
                <v-col cols="auto">
                  <v-checkbox
                    v-model="item.aprovacao_qtd_reclamacao"
                    hide-details
                    :disabled="
                      savingAprovacoesValidacoes ||
                        !userIsAuthorizedAprovacaoQtdReclamacao() ||
                        (!authorizedCompanies.includes(
                          $store.getters.getCompanyId
                        ) &&
                          !isAdmin)
                    "
                    :ripple="false"
                    :indeterminate="item.aprovacao_qtd_reclamacao === null"
                    :success="item.aprovacao_qtd_reclamacao === true"
                    :error="item.aprovacao_qtd_reclamacao === false"
                    class="my-0"
                  >
                    <template v-slot:label>
                      <div
                        class="font-weight-medium"
                        :class="
                          getCheckboxAprovacaoClass(
                            item.aprovacao_qtd_reclamacao
                          )
                        "
                        style="font-size: 12.5px"
                      >
                        {{
                          getCheckboxAprovacaoText(
                            item.aprovacao_qtd_reclamacao
                          )
                        }}
                      </div>
                    </template>
                  </v-checkbox>
                </v-col>
              </v-row>
            </template>
            <template v-slot:[`item.aprovacao_datas`]="{ item }">
              <v-row no-gutters justify="end">
                <v-col cols="auto">
                  <v-checkbox
                    v-model="item.aprovacao_datas"
                    hide-details
                    :disabled="
                      savingAprovacoesValidacoes ||
                        !userIsAuthorizedAprovacaoDatas() ||
                        (!authorizedCompanies.includes(
                          $store.getters.getCompanyId
                        ) &&
                          !isAdmin)
                    "
                    :ripple="false"
                    :indeterminate="item.aprovacao_datas === null"
                    :success="item.aprovacao_datas === true"
                    :error="item.aprovacao_datas === false"
                    class="my-0"
                  >
                    <template v-slot:label>
                      <div
                        class="font-weight-medium"
                        :class="getCheckboxAprovacaoClass(item.aprovacao_datas)"
                        style="font-size: 12.5px"
                      >
                        {{ getCheckboxAprovacaoText(item.aprovacao_datas) }}
                      </div>
                    </template>
                  </v-checkbox>
                </v-col>
              </v-row>
            </template>
          </v-data-table>
        </v-card-text>
        <v-divider />
        <v-card-actions class="pt-0 pr-6">
          <v-spacer />
          <v-tooltip
            top
            v-if="
              (!userIsAuthorizedAprovacaoQtdConsumidor() &&
                !userIsAuthorizedAprovacaoQtdReclamacao() &&
                !userIsAuthorizedAprovacaoDatas()) ||
                (!authorizedCompanies.includes($store.getters.getCompanyId) &&
                  !isAdmin)
            "
          >
            <template v-slot:activator="{ on, attrs }">
              <div class="pr-2" v-bind="attrs" v-on="on">
                <v-icon large color="warning">
                  mdi-alert-circle-outline
                </v-icon>
              </div>
            </template>
            <span
              >Você não possui autorização para realizar essa validação</span
            >
          </v-tooltip>
          <v-btn
            color="success"
            :disabled="
              (!userIsAuthorizedAprovacaoQtdConsumidor() &&
                !userIsAuthorizedAprovacaoQtdReclamacao() &&
                !userIsAuthorizedAprovacaoDatas()) ||
                (!authorizedCompanies.includes($store.getters.getCompanyId) &&
                  !isAdmin)
            "
            :loading="savingAprovacoesValidacoes"
            @click="salvarAprovacoesValidacoes"
          >
            Salvar
          </v-btn>
          <v-btn
            text
            color="primary"
            class="text-h2 pa-12"
            @click="dialogDetalhes = false"
          >
            Fechar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </span>
</template>

<script>
  import PainelTecnicosService from "@/services/PainelTecnicosService.js";

  export default {
    name: "TecnicosVisaoDetalhadaDialogDetalhes",
    props: {
      competencia_de: {
        type: String,
        required: true,
      },
      competencia_ate: {
        type: String,
        required: true,
      },
      authorizedCategoriesTecAl: {
        type: Array,
        required: false,
      },
      authorizedCategoriesTecSub: {
        type: Array,
        required: false,
      },
      authorizedCategoriesTecLd: {
        type: Array,
        required: false,
      },
      authorizedCompanies: {
        type: Array,
        required: false,
      },
    },
    data: () => ({
      base: null,
      dialogDetalhes: false,
      dialogDetalhesData: [],
      page: 1,
      validacoes: {
        AL: [
          "qtd_consumidor",
          "qtd_reclamacao",
          "inspecao_visual",
          "manutencao_preventiva",
          "manutencao_corretiva",
        ],
        SE: [
          "qtd_consumidor",
          "qtd_reclamacao",
          "inspecao_visual",
          "inspecao_termografica",
          "manutencao_preventiva",
          "manutencao_corretiva",
        ],
        LD: [
          "inspecao_visual",
          "inspecao_termografica",
          "manutencao_preventiva",
          "manutencao_corretiva",
        ],
      },
      validacoesDatas: {
        AL: [
          "inspecao_visual",
          "manutencao_preventiva",
          "manutencao_corretiva",
        ],
        SE: [
          "inspecao_visual",
          "inspecao_termografica",
          "manutencao_preventiva",
          "manutencao_corretiva",
        ],
        LD: [
          "inspecao_visual",
          "inspecao_termografica",
          "manutencao_preventiva",
          "manutencao_corretiva",
        ],
      },
      aprovacoes: {
        AL: [
          "aprovacao_qtd_consumidor",
          "aprovacao_qtd_reclamacao",
          "aprovacao_datas",
        ],
        SE: [
          "aprovacao_qtd_consumidor",
          "aprovacao_qtd_reclamacao",
          "aprovacao_datas",
        ],
        LD: ["aprovacao_datas"],
      },
      headers: [
        { text: "Qtd Consumidor", value: "qtd_consumidor", align: "end" },
        { text: "Qtd Reclamação", value: "qtd_reclamacao", align: "end" },
        { text: "Inspeção Visual", value: "inspecao_visual", align: "end" },
        {
          text: "Inspeção Termográfica",
          value: "inspecao_termografica",
          align: "end",
        },
        {
          text: "Manutenção Preventiva",
          value: "manutencao_preventiva",
          align: "end",
        },
        {
          text: "Manutenção Corretiva",
          value: "manutencao_corretiva",
          align: "end",
        },
        {
          text: "Aprovação Qtd Consumidor",
          value: "aprovacao_qtd_consumidor",
          sortable: false,
          align: "end",
        },
        {
          text: "Aprovação Qtd Reclamação",
          value: "aprovacao_qtd_reclamacao",
          sortable: false,
          align: "end",
        },
        {
          text: "Aprovação Datas",
          value: "aprovacao_datas",
          sortable: false,
          align: "end",
        },
      ],
      keyHeaders: {
        AL: [
          { text: "TECAL_002", value: "tecal_002", align: "end" },
          { text: "Competência", value: "competencia", align: "end" },
          { text: "Alimentador", value: "tecal_005", align: "end" },
        ],
        SE: [
          { text: "TECSUB_002", value: "tecsub_002", align: "end" },
          { text: "Competência", value: "competencia", align: "end" },
          { text: "Subestação", value: "tecsub_005", align: "end" },
        ],
        LD: [
          { text: "TECLD_002", value: "tecld_002", align: "end" },
          { text: "Competência", value: "competencia", align: "end" },
          { text: "Linha de Distribuição", value: "tecld_005", align: "end" },
        ],
      },
      availableHeaders: [],
      savingAprovacoesValidacoes: false,
      mostrarApenasLinhasComErroCritico: false,
      mostrarApenasDatasIgualCompetencia: false,
      flagTodosAprovadoQtdConsumidor: false,
      flagTodosAprovadoQtdReclamacao: false,
      flagTodosAprovadoDatas: false,
      downloadingArquivoLogsAprovacoes: false,
    }),
    computed: {
      userInfo() {
        return this.$store.getters.getUserInfo;
      },
      filteredItems() {
        let items = this.dialogDetalhesData;

        if (this.mostrarApenasLinhasComErroCritico) {
          items = items.filter((data) => {
            let found = false;

            for (const validacao of this.validacoes[this.base]) {
              found = data[`tipo_validacao_${validacao}`] === "ERRO_CRITICO";

              if (found === true) break;
            }

            return found;
          });
        }

        if (this.mostrarApenasDatasIgualCompetencia) {
          items = items.filter((data) => {
            let bool = false;

            for (const validacao of this.validacoesDatas[this.base]) {
              bool = data[validacao] === data.competencia;

              if (bool === false) break;
            }

            return bool;
          });
        }

        return items;
      },
      isAdmin() {
        return this.$store.getters.getUserInfo.admin;
      },
    },
    methods: {
      getDados(base) {
        this.$emit("updateLoadingDados", true);

        PainelTecnicosService.getDadosValidacoes(
          this.competencia_de,
          this.competencia_ate,
          base
        )
          .then((res) => {
            this.dialogDetalhesData = res;

            this.setAvailableHeaders(base);

            this.base = base;
            this.dialogDetalhes = true;
          })
          .catch((err) => {
            this.$toast.error(
              "Erro ao recuperar dados detalhados das validações.",
              "",
              {
                position: "topRight",
                timeout: 5000,
              }
            );
            console.error(err);
          })
          .finally(() => {
            this.$emit("updateLoadingDados", false);
          });
      },
      setAvailableHeaders(base) {
        this.availableHeaders = [];
        const headersValidos = this.validacoes[base].concat(
          this.aprovacoes[base]
        );

        this.availableHeaders = this.keyHeaders[base].concat(
          this.headers.filter((header) => {
            // Exibe apenas validações e aprovações disponiveis para a base
            return headersValidos.some((val) => val.includes(header.value));
          })
        );
      },
      salvarAprovacoesValidacoes() {
        this.savingAprovacoesValidacoes = true;

        PainelTecnicosService.salvarAprovacoesValidacoes(this.base, {
          authorization: {
            QuantidadeDeConsumidores: this.userIsAuthorizedAprovacaoQtdConsumidor(),
            QuantidadeDeReclamacaoPorFaltaDeEnergia: this.userIsAuthorizedAprovacaoQtdReclamacao(),
            Manutencoes: this.userIsAuthorizedAprovacaoDatas(),
          },
          dados: this.dialogDetalhesData,
        })
          .then((response) => {
            this.$toast.success(`Aprovações salvas com sucesso.`, "", {
              position: "topRight",
              timeout: 3000,
            });

            this.dialogDetalhes = false;
            this.$emit("updateDataTable");
          })
          .catch((error) => {
            this.$toast.error("Erro salvar aprovações.", "", {
              position: "topRight",
              timeout: 5000,
            });
            console.error(error);
          })
          .finally(() => (this.savingAprovacoesValidacoes = false));
      },
      triggerTodosAprovadoQtdConsumidor() {
        this.filteredItems
          .map((data) => data.id_validacao)
          .forEach((id_validacao) => {
            const index = this.dialogDetalhesData.findIndex(
              (data) => data.id_validacao === id_validacao
            );

            // Não marca como aprovado caso não esteja dentro do filtro
            if (index !== -1) {
              this.dialogDetalhesData[
                index
              ].aprovacao_qtd_consumidor = this.flagTodosAprovadoQtdConsumidor;
            }
          });
      },
      triggerTodosAprovadoQtdReclamacao() {
        this.filteredItems
          .map((data) => data.id_validacao)
          .forEach((id_validacao) => {
            const index = this.dialogDetalhesData.findIndex(
              (data) => data.id_validacao === id_validacao
            );

            // Não marca como aprovado caso não esteja dentro do filtro
            if (index !== -1) {
              this.dialogDetalhesData[
                index
              ].aprovacao_qtd_reclamacao = this.flagTodosAprovadoQtdReclamacao;
            }
          });
      },
      triggerTodosAprovadoDatas() {
        this.filteredItems
          .map((data) => data.id_validacao)
          .forEach((id_validacao) => {
            const index = this.dialogDetalhesData.findIndex(
              (data) => data.id_validacao === id_validacao
            );

            // Não marca como aprovado caso não esteja dentro do filtro
            if (index !== -1) {
              this.dialogDetalhesData[
                index
              ].aprovacao_datas = this.flagTodosAprovadoDatas;
            }
          });
      },
      getHeaderSlotName(name) {
        return `header.${name}`;
      },
      getItemSlotName(indicador) {
        return `item.${indicador}`;
      },
      checkTiposValidacoesDatasContainTipoValidacao(item, tipoValidacao) {
        switch (this.base) {
          case "AL":
            return (
              item.tipo_validacao_inspecao_visual == tipoValidacao ||
              item.tipo_validacao_manutencao_preventiva == tipoValidacao ||
              item.tipo_validacao_manutencao_corretiva == tipoValidacao
            );
          case "SE":
          case "LD":
            return (
              item.tipo_validacao_inspecao_visual == tipoValidacao ||
              item.tipo_validacao_inspecao_termografica == tipoValidacao ||
              item.tipo_validacao_manutencao_preventiva == tipoValidacao ||
              item.tipo_validacao_manutencao_corretiva == tipoValidacao
            );
          default:
            return false;
        }
      },
      getCheckboxAprovacaoClass(aprovacao) {
        if (aprovacao === true) return "success--text";
        if (aprovacao === false) return "error--text";
        return "info--text";
      },
      getCheckboxAprovacaoText(aprovacao) {
        if (aprovacao === true) return "Aprovado";
        if (aprovacao === false) return "Reprovado";
        return "Em análise";
      },
      downloadArquivoLogsAprovacoes() {
        this.downloadingArquivoLogsAprovacoes = true;

        this.$toast.info("Preparando seu arquivo para download.", "", {
          position: "topRight",
          timeout: 3000,
        });

        PainelTecnicosService.downloadArquivoLogsAprovacoes(
          this.competencia_de,
          this.competencia_ate,
          this.base,
          { timeout: 60 * 60 * 1000 }
        )
          .then(async (res) => {
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute(
              "download",
              `Logs_Aprovacoes_Validacoes_Tecnicos_${this.base}.xlsx`
            );
            document.body.appendChild(link);
            link.click();
          })
          .catch((err) => {
            this.$toast.error("Erro no download do arquivo.", "", {
              position: "topRight",
              timeout: 5000,
            });
            throw err;
          })
          .finally(() => (this.downloadingArquivoLogsAprovacoes = false));
      },
      userIsAuthorizedAprovacaoQtdConsumidor() {
        if (this.userInfo.admin) return true;
        if (!this.userInfo.validador) return false;

        switch (this.base) {
          case "AL":
            return this.authorizedCategoriesTecAl.includes(
              "QuantidadeDeConsumidores"
            );
          case "SE":
            return this.authorizedCategoriesTecSub.includes(
              "QuantidadeDeConsumidores"
            );
          case "LD":
          default:
            return false;
        }
      },
      userIsAuthorizedAprovacaoQtdReclamacao() {
        if (this.userInfo.admin) return true;
        if (!this.userInfo.validador) return false;

        switch (this.base) {
          case "AL":
            return this.authorizedCategoriesTecAl.includes(
              "QuantidadeDeReclamacaoPorFaltaDeEnergia"
            );
          case "SE":
            return this.authorizedCategoriesTecSub.includes(
              "QuantidadeDeReclamacaoPorFaltaDeEnergia"
            );
          case "LD":
          default:
            return false;
        }
      },
      userIsAuthorizedAprovacaoDatas() {
        if (this.userInfo.admin) return true;
        if (!this.userInfo.validador) return false;

        switch (this.base) {
          case "AL":
            return this.authorizedCategoriesTecAl.includes("Manutencoes");
          case "SE":
            return this.authorizedCategoriesTecSub.includes("Manutencoes");
          case "LD":
            return this.authorizedCategoriesTecLd.includes("Manutencoes");
          default:
            return false;
        }
      },
    },
    watch: {
      filteredItems() {
        this.page = 1;

        this.flagTodosAprovadoQtdConsumidor = this.filteredItems.every(
          (data) => data.aprovacao_qtd_consumidor === true
        );

        this.flagTodosAprovadoQtdReclamacao = this.filteredItems.every(
          (data) => data.aprovacao_qtd_reclamacao === true
        );

        this.flagTodosAprovadoDatas = this.filteredItems.every(
          (data) => data.aprovacao_datas === true
        );
      },
    },
  };
</script>

<style>
  .style-table-dialog-detalhes table thead tr th {
    font-weight: 400;
    font-size: 14px;
    height: 33px;
  }

  .style-table-dialog-detalhes table tbody tr td {
    word-wrap: break-word;
    max-width: 240px;
    font-size: 12.5px;
    padding: 0px 8px !important;
    height: 35px;
    text-align: end !important;
  }
</style>
